import type { ComponentProps } from "react";

export default function KpiIcon(svgProps: ComponentProps<"svg">) {
  return (
    <svg
      viewBox="0 0 25 25"
      width="21"
      height="20"
      fill="currentColor"
      aria-label="Logout"
      {...svgProps}
    >
      <g>
        <path d="M21.29 6.83a.5.5 0 0 1-.5-.5V5.5a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v.83A.5.5 0 0 1 21.29 6.83zM21.29 11a.5.5 0 0 1-.5-.5V9.67a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v.83A.5.5 0 0 1 21.29 11z"></path>
        <path d="M21.67 10.17H20.46a.5.5 0 0 1-.5-.5.5.5 0 0 1 .5-.5h1.21a.34.34 0 0 0 0-.67h-.8a1.34 1.34 0 0 1 0-2.67h1.22a.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5H20.87a.34.34 0 0 0 0 .67h.8a1.34 1.34 0 0 1 0 2.67zM5.5 17a.47.47 0 0 1-.35-.15.48.48 0 0 1 0-.7l4-4a.49.49 0 0 1 .57-.1l3.68 1.84 3.75-3.74a.49.49 0 1 1 .7.7l-4 4a.49.49 0 0 1-.57.1L9.6 13.11 5.85 16.85A.47.47 0 0 1 5.5 17z"></path>
        <circle cx="5.5" cy="16.5" r="1"></circle>
        <circle cx="9.53" cy="12.54" r="1"></circle>
        <circle cx="13.5" cy="14.5" r="1"></circle>
        <circle cx="17.5" cy="10.5" r="1"></circle>
        <path d="M22.5 20a.51.51 0 0 1-.5-.5.41.41 0 0 1 0-.19.36.36 0 0 1 .11-.16.5.5 0 0 1 .7 0 .36.36 0 0 1 .11.16.41.41 0 0 1 0 .19.47.47 0 0 1-.15.35.36.36 0 0 1-.16.11A.43.43 0 0 1 22.5 20zM20.53 20H2.5a.5.5 0 0 1-.5-.5V5.5a.5.5 0 0 1 1 0V19H20.53a.5.5 0 0 1 0 1z"></path>
      </g>
    </svg>
  );
}

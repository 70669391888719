/* eslint-disable react-refresh/only-export-components */
import { useRouter } from "@tanstack/react-router";
import {
  type PropsWithChildren,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useMediaQuery } from "../hooks/useMediaQuery";

export type HandleSetHeaderDataArgs = {
  hasBackButton: boolean;
  children?: ReactNode;
  onBackClick?: () => void;
};

type HeaderContextType = {
  isSideBarOpen: boolean;
  hasBackButton: boolean;
  component?: ReactNode;
  toggleSideBar: () => void;
  handleSetHeaderData: (props: HandleSetHeaderDataArgs) => void;
  onBackClick?: () => void;
};

const HeaderContext = createContext<HeaderContextType>({
  isSideBarOpen: false,
  hasBackButton: false,
  component: null,
  toggleSideBar: () => {},
  handleSetHeaderData: () => {},
  onBackClick: () => {},
});

export const HeaderProvider = ({ children }: PropsWithChildren) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [hasBackButton, setHasBackButton] = useState(false);
  const [component, setComponent] = useState<ReactNode>();
  const [onBackClick, setOnBackClick] = useState<() => void>();

  const router = useRouter();

  const toggleSideBar = () => {
    if (isMobile) setIsSideBarOpen(!isSideBarOpen);
  };

  const handleSetHeaderData = useCallback(
    ({ children, hasBackButton, onBackClick }: HandleSetHeaderDataArgs) => {
      setComponent(children);
      setHasBackButton(!!hasBackButton);
      if (onBackClick) {
        setOnBackClick(() => onBackClick);
      }
    },
    [],
  );

  useEffect(() => {
    const [url, _] = router.state.location.href.split("#");
    const isStillInSameRoute = url === router.state.location.pathname;
    if (router.state.isLoading && !isStillInSameRoute) {
      setComponent(null);
      setHasBackButton(false);
      setOnBackClick(undefined);
    }
  }, [router.state.isLoading, router.state.location]);

  return (
    <HeaderContext.Provider
      value={{
        isSideBarOpen,
        hasBackButton,
        component,
        toggleSideBar,
        handleSetHeaderData,
        onBackClick,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderContext = () => {
  return useContext(HeaderContext);
};
